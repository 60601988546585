import React from 'react';
import PropTypes from 'prop-types';

const copy = {
  'seller-costs':
    'Clever pre-negotiates low rates with top agents nationwide. Compare local agents, get full service, save up to 50% on realtor fees at closing.',
  'title-fee':
    'Finding your realtor through Clever Real Estate’s free agent matching service could save you thousands when you sell or buy.',
};

const ContentQuickTip = ({ type }) => (
  <section className="flex items-start p-6 my-6 rounded-lg bg-primary-200">
    <span className="mb-4 mr-2 text-xl font-heading text-primary-800">⚡️</span>
    <p className="flex-1 mb-2">
      <span className="font-bold">Quick Tip:</span> {`${copy[type]} `}
      <a
        rel="nofollow"
        href="https://go.anytimeestimate.com/anytime-estimate-clever-flat-fee/"
      >
        Learn more.
      </a>
    </p>
  </section>
);

ContentQuickTip.propTypes = {
  type: PropTypes.string,
};

ContentQuickTip.defaultProps = {
  type: 'seller-costs',
};

export default ContentQuickTip;
