import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import '../calculators/styles/calculators.scss';
import Layout from '../components/layout/Layout';
import SEO from '../components/meta/SEO';
import ContentQuickTip from '../components/molecules/ContentQuickTip';
import ContentFooterCTA from '../components/molecules/ContentFooterCTA';

const CalculatorTemplate = ({ pageContext, path }) => {
  const {
    title,
    description,
    image_url: image,
    newPath,
    content,
    scripts,
    styles,
  } = pageContext;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const sellerCostsQuickTip = document.querySelector(
        '#seller-costs-quick-tip',
      );
      const sellerCostsCta = document.querySelector('#seller-costs-cta');
      const titleFeeQuickTip = document.querySelector('#title-fee-quick-tip');
      const titleFeeCta = document.querySelector('#title-fee-cta');

      if (sellerCostsQuickTip) {
        ReactDOM.render(
          <ContentQuickTip />,
          document.getElementById('seller-costs-quick-tip'),
        );
      }

      if (sellerCostsCta) {
        ReactDOM.render(
          <ContentFooterCTA />,
          document.getElementById('seller-costs-cta'),
        );
      }

      if (titleFeeQuickTip) {
        ReactDOM.render(
          <ContentQuickTip type="title-fee" />,
          document.getElementById('title-fee-quick-tip'),
        );
      }

      if (titleFeeCta) {
        ReactDOM.render(
          <ContentFooterCTA type="title-fee" />,
          document.getElementById('title-fee-cta'),
        );
      }
    }
  }, []);

  return (
    <Layout>
      <SEO
        pathname={path}
        canonical={newPath}
        title={title}
        description={description}
        image={image}
        rawScripts={scripts}
        rawStyles={styles}
      />
      <div
        id="calculator-container"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </Layout>
  );
};

CalculatorTemplate.propTypes = {
  pageContext: PropTypes.any,
  path: PropTypes.string.isRequired,
};

CalculatorTemplate.defaultProps = {
  pageContext: {},
};

export default CalculatorTemplate;
