/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../atoms/Button';

const copy = {
  'seller-costs': {
    title: '💰 Match with top local agents, save thousands on commission fees.',
    body: 'Clever pre-negotiates low rates with top agents nationwide.<br/>Compare local agents, get full service, save up to 50% on realtor fees at closing.<br/>Clever is free to use with no obligation. Give it a try today!',
    buttonText: 'Get Started',
  },
  'title-fee': {
    title:
      '💰 Match with top local realtors, get big savings when you sell or buy.',
    body: 'Clever pre-negotiates special rates with top agents nationwide.<br/>Choose from the best local agents, get full service, save thousands.<br/>Clever is free to use with no obligation. Give it a try today!',
    buttonText: 'View Agents',
  },
};

const ContentFooterCTA = ({ type }) => {
  const handleClick = e => {
    let landingPageData = '';
    const currentTime = new Date().getTime();
    const sessionTime = 60 * 60 * 1000;
    if (localStorage.getItem('landing_page')) {
      landingPageData = JSON.parse(localStorage.getItem('landing_page'));
      if (
        !landingPageData ||
        currentTime - landingPageData.timestamp > sessionTime
      ) {
        localStorage.setItem(
          'landing_page',
          JSON.stringify({
            path: window.location.href,
            timestamp: currentTime,
          }),
        );
      }
    } else {
      localStorage.setItem(
        'landing_page',
        JSON.stringify({
          path: window.location.href,
          timestamp: currentTime,
        }),
      );
      landingPageData = {
        path: window.location.href,
        timestamp: currentTime,
      };
    }
    window.location.href = `https://go.anytimeestimate.com/anytime-estimate-clever-flat-fee/?first_page_seen=${landingPageData.path}&referrer=${window.location.href}`;
    e.preventDefault();
  };

  return (
    <section className="flex flex-col items-center p-8 my-6 text-center rounded-lg bg-primary-200">
      <span
        className="mb-3 text-xl font-bold font-heading text-primary-800"
        role="heading"
        aria-level="3"
      >
        {copy[type].title}
      </span>
      <p
        className="mb-6 leading-8"
        dangerouslySetInnerHTML={{ __html: copy[type].body }}
      />
      <Button
        buttonStyle="primary"
        label={copy[type].buttonText}
        onClick={handleClick}
      />
    </section>
  );
};

ContentFooterCTA.propTypes = {
  type: PropTypes.string,
};

ContentFooterCTA.defaultProps = {
  type: 'seller-costs',
};

export default ContentFooterCTA;
